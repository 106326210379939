import { useState, useContext } from 'react';
import { useRouter } from 'next/router';
import Alert from '@mui/material/Alert';

import { Container, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

// layout
import MainLayout from '/layouts/MainLayout';

// components
import Button from '/components/shared/Button';

// actions
import { login } from '/context/actions/auth';

// store
import { GlobalContext } from '/context';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    // loadingContainer: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     height: '100vh',
    // },
}));

export default function Login() {
    const classes = useStyles();
    const router = useRouter();
    const { dispatch } = useContext(GlobalContext);
    const [phone, setPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);

            await login(dispatch, {
                phone,
                password,
            }).then((result) => {
                setIsLoading(false);
                if (result.code === 200) {
                    router.push('/admin/news');
                } else setErrorMsg('Нэр, нууц үгээ шалгана уу!!!');
            });
        } catch (error) {
            setErrorMsg('Нэр, нууц үгээ шалгана уу!!!');
        }
    };

    return (
        <Container maxWidth="lg" className={classes.root}>
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
                        Нэвтрэх
                    </h2>
                </div>
                <div className="mt-8 space-y-6">
                    {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email-address" className="sr-only">
                                Утасны дугаар
                            </label>
                            <input
                                id="email-address"
                                name="phone"
                                type="string"
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                                className="appearance-none rounded-none relative block w-full my-3 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Имэйл хаяг"
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                Нууц үг
                            </label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                placeholder="Нууц үг"
                            />
                        </div>
                    </div>
                    {isLoading && (
                        <div className={classes.loadingContainer}>
                            <LinearProgress />
                        </div>
                    )}
                    <div>
                        <Button onClick={handleSubmit} name="Нэвтрэх" />
                    </div>
                    {errorMsg && (
                        <Alert severity="error" className={classes.errorMessage}>
                            {errorMsg}
                        </Alert>
                    )}
                </div>
            </div>
        </Container>
    );
}

Login.Layout = MainLayout;
